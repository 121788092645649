  
.pagination {
    display: flex;
    justify-content: center;
    padding-left: 0;
    list-style: none;
   
  }
  .pagination > .active > a{
    color: #fff;
    background-color: #eaeaea;
    border-color: #eaeaea;
  }
  .pagination > li > a{
    border-radius: 50%;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #1b75bc ;
    outline: none ;
  }
  .pagination > li > a, .pagination > li > span{
    
    border-radius: 50%
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    
    border-radius: 50%
  }